<script setup lang="ts">
createSamplesContext()
</script>

<template>
  <div flex flex-col flex-1>
    <AppHeader />
    <div flex-1 flex>
      <router-view />
    </div>
  </div>
</template>
