<script setup lang="ts" generic="TValue">
const {
  name: nameProp,
  keySelector = (_, index): number | string => index,
} = defineProps<{
  name?: string
  options: Array<{ name: string, value: TValue }>
  keySelector?: (option: { name: string, value: TValue }, index: number) => string | number
}>()

const context = useFieldContext()

const name = computed(() => nameProp ?? context?.name)

const model = defineModel<TValue>()
</script>

<template>
  <select
    :id="name"
    v-model="model"
    :name="name"
    bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
  >
    <option
      v-for="(option, index) in options"
      :key="keySelector(option, index)"
      :value="option.value"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<style scoped>

</style>
