<template>
  <header flex-none w-full h-20 flex justify-between shadow dark:shadow-gray-700>
    <div flex items-center ml-6 gap-4>
      <h1 text-2xl font-medium>
        <RouterLink to="/">
          Stand Configurator Sandbox
        </RouterLink>
      </h1>

      <div>
        <SampleSelector />
      </div>
    </div>

    <div flex gap-6 mx-6 items-stretch>
      <slot name="header-right-pre" />

      <a href="javascript:{}" text-gray-800 dark:text-gray-200 hover:text-blue-400 flex items-center>
        <div i-heroicons-document h-8 w-8 />
        <span ml-2>Upload guide</span>
      </a>

      <a href="javascript:{}" text-gray-800 dark:text-gray-200 hover:text-blue-400 flex items-center>
        <div i-heroicons-information-circle h-8 w-8 />
        <span ml-2>FAQ</span>
      </a>

      <a href="#" text-gray-800 dark:text-gray-200 hover:text-gray-300 flex items-center>
        AoF Logo
      </a>

      <AppDarkModeToggle />
    </div>
  </header>
</template>
