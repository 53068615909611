<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue'
import type { SampleContext } from '@/composables/useSamples.ts'

const { samples, selected } = useSamplesContext()
const options = computed(() => [
  {
    value: undefined,
    name: '---',
  },
  ...samples.map(sample => ({
    value: sample.name,
    name: sample.name,
  }))])
const model = computed<string | undefined>({
  get: () => selected.value?.name,
  set: (value): void => {
    const sample = samples.find(sample => sample.name === value)

    if (sample && !sample.loaded) {
      sample.loadOnce()
    }

    selected.value = sample as UnwrapNestedRefs<SampleContext> | undefined
  },
})
</script>

<template>
  <AppSelect
    v-model="model"
    :options="options"
  />
</template>
