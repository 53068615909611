import type { InjectionKey, UnwrapNestedRefs } from 'vue'

export type FieldContext = {
  name: string
}

const FieldContextKey = Symbol('FieldContext') as InjectionKey<UnwrapNestedRefs<FieldContext>>

export function createFieldContext(factory: () => FieldContext): void {
  provide(FieldContextKey, reactive(factory()))
}

export function useFieldContext(): FieldContext | undefined {
  return inject(FieldContextKey, undefined)
}
