import type { StandTemplate } from '@artoffiber/stand-configurator'
import type { UnwrapNestedRefs } from 'vue'

const samplesMap = {
  'Initial Demo Objects': (): Promise<any> => fetch('/samples/demoObjects.json').then(response => response.json()),
  'Sample stand with decals 1': (): Promise<any> => fetch('/samples/sampleStandWithDecals1.json').then(response => response.json()),
  'Sample stand with decals 2': (): Promise<any> => fetch('/samples/sampleStandWithDecals2.json').then(response => response.json()),
  'Sample stand Art of Fiber': (): Promise<any> => fetch('/samples/sampleStandAof.json').then(response => response.json()),
  'Sample stand Art of Fiber Fascia': (): Promise<any> => fetch('/samples/sampleStandAofFascia.json').then(response => response.json()),
}

export type SampleContext = {
  name: string
  template: Ref<StandTemplate | undefined>
  loaded: Ref<boolean>
  loading: Ref<boolean>
  error: Ref<unknown | undefined>
  load: () => Promise<void>
  loadOnce: () => Promise<void>
}

export type SamplesContext = {
  samples: UnwrapNestedRefs<Array<SampleContext>>
  selected: Ref<UnwrapNestedRefs<SampleContext> | undefined>
}

function createSampleContext({ name, loadSample }: { name: string, loadSample: () => Promise<StandTemplate> }): SampleContext {
  const template = ref<StandTemplate>()
  const loaded = ref(false)
  const loading = ref(false)
  const error = ref<unknown | undefined>()

  async function load(): Promise<void> {
    if (loading.value) {
      return
    }
    loading.value = true
    try {
      const sampleData = await loadSample()
      template.value = sampleData
      loaded.value = true
    } catch (e) {
      error.value = e
    } finally {
      loading.value = false
    }
  }

  let loadPromise: Promise<void> | undefined
  async function loadOnce(): Promise<void> {
    if (loadPromise) {
      return loadPromise
    }
    if (loaded.value) {
      return
    }
    try {
      loadPromise = load()
      await loadPromise
    } finally {
      loadPromise = undefined
    }
  }

  return {
    name,
    template,
    loaded,
    loading,
    error,
    load,
    loadOnce,
  }
}

export const SamplesContextKey = Symbol('SamplesContext') as InjectionKey<SamplesContext>

export function createSamplesContext(): SamplesContext {
  const rawSamples = Object.entries(samplesMap).map(([name, loadSample]) => createSampleContext({ name, loadSample }))

  const samples = {
    samples: reactive(rawSamples),
    selected: ref(),
  }

  provide(SamplesContextKey, samples)

  return samples
}

export function useSamplesContext(): SamplesContext {
  const samples = inject(SamplesContextKey)

  if (!samples) {
    throw new Error('useSamples() is called without provider')
  }

  return samples
}
