<script setup lang="ts">
const toggleDarkMode = (): boolean => toggleDark()
</script>

<template>
  <button type="button" text-gray-800 dark:text-gray-200 hover:text-blue-400 flex items-center px-2 @click="toggleDarkMode">
    <div
      :class="{
        'i-heroicons:sun': !isDark,
        'i-heroicons:moon': isDark,
      }"
    />
  </Button>
</template>
