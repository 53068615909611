export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/app/playground/sandbox-vue/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/configurator',
    /* internal name: '/configurator' */
    /* no component */
    children: [
      {
        path: '',
        name: '/configurator/',
        component: () => import('/app/playground/sandbox-vue/src/pages/configurator/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/storefront',
    /* internal name: '/storefront' */
    /* no component */
    children: [
      {
        path: '',
        name: '/storefront/',
        component: () => import('/app/playground/sandbox-vue/src/pages/storefront/index.vue'),
        /* no children */
      }
    ],
  }
]
